import React from 'react'

import "./AdPlaceHolder.css";

export const AdPlaceHolder = () => {
    return (
        <div className='ad-place-holder'>
            <h1><span>A</span>D</h1>
        </div>
    )
}
